<template>
  <div class="privacy-single py-[24vw] md:py-[8vw] relative overflow-hidden">
    <SvgShapeGearSharp class="hidden md:block absolute text-yellow w-[13.625vw] h-[13.625vw] top-[17.5vw] md:top-[40vw] left-[-4.8vw]" />
    <SvgShapeCrescent class="rotate-[135deg] absolute top-[1vw] md:top-[-3vw] right-0 text-turquoise h-[40vw] w-[20vw] md:h-[20vw] md:w-[10vw]" />
    <SvgShapeHalfCircle class="hidden md:block absolute text-pink h-[8vw] w-[16vw] top-[3vw] left-[30vw]" />

    <!-- content.legal does not necesserally indicate a "legal" document since there are documents that fit this category and are marked as false. It is primarily used as a flag for displaying the promo section or not -->
    <Container v-if="(!content.legal || content.legal !== true) && geoIpCountry !== 'US'"
               class="pb-[-4vw] md:pb-[2.5vw]"
    >
      <CardPromoPrivacy v-if="promoContent"
                        v-bind="promoContent.promo"
      />
    </Container>

    <BlogContainer>
      <div v-if="filteredLangs?.length > 1"
           class="relative md:col-span-8 md:col-start-3"
      >
        <PrivacyNavigation :language="language"
                           :slug="content.slug"
                           :filtered-langs="filteredLangs"
        />
      </div>

      <div class="prose md:col-span-8 md:col-start-3"
           :style="{ direction: ltr ? 'ltr' : 'rtl' }"
      >
        <ContentRenderer :value="content" />
      </div>
    </BlogContainer>
  </div>
</template>

<script setup lang="ts">
import { useAsyncData, queryContent, useHead } from '#imports';
import { computed } from 'vue';
import useGeoIp from '~/composables/useGeoIp';
import usePrivacyNavigation from '~/composables/use-privacy-navigation';

type TemplatesPrivacySingleProps = {
    content: any;
    language: string | undefined;
}

const props = defineProps<TemplatesPrivacySingleProps>();

const filteredLangs = await usePrivacyNavigation(props.content._dir);

const { geoIpCountry, geoLocate } = useGeoIp();
geoLocate();

const { data } = await useAsyncData('general', () => queryContent('globals', 'general').findOne());

const promoContent = computed(() => {
  if (data && data.value && data.value.promo) {
    return data.value;
  }

  return false;
});

/**
 * The LTR property is defined the wrong way in the content
 *
 * LTR = true means the text is RTL
 * LTR = false means the text is LTR
 */
const ltr = computed(() => {
  return !props.content.ltr;
});

useHead({
  title: props.content.title,
  meta: [
    { hid: 'og:title', property: 'og:title', content: props.content.title },
    { hid: 'twitter:title', name: 'twitter:title', content: props.content.title }
  ]
});
</script>

<style scoped lang="scss">
.privacy-single:deep(.table-scroll-wrapper),
.privacy-single:deep(.table-scroll-wrapper-wide) {
  overflow-x: auto;

  table {
    width: max-content;
    max-width: 400vw;

    @media screen and (min-width: 768px) {
      max-width: 200vw
    }
  }
}
</style>